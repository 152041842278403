import React from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { Form, Field } from 'react-final-form';
import config from '../../config';
import { history } from '../../routes';
import { ToastContainer, notify } from '../../libraries/notifications';
import { capitalize, isEmptyObject, validateIsfilled, selectGeneratorWObjChild, formatOnlyNumberInput, getOwner, replaceNewLines, validateURL, parseUrl, optionSelectGenerator, } from '../../libraries/utils';
import FormLayout from '../../components/forms/FormLayout';
import transactionsActions from '../../context/transactions/actions';
import categoriesActions from '../../context/categories/actions';
import companiesActions from "../../context/companies/actions";
import filesActions from '../../context/files/actions';
import usersActions from '../../context/users/actions';
import benefitsActions from '../../context/benefits/actions';
import LayoutWithSidebar from '../../components/layout/LayoutWithSidebar';
import PanelWindow from '../../components/PanelWindow';
import TextInput from '../../components/forms/TextInput';
import SelectInput from '../../components/forms/SelectInput';
import ImageUploadFileInput from '../../components/forms/ImageUploadFileInput';
import IntegerInput from '../../components/forms/IntegerInput';
import TextareaInput from '../../components/forms/TextareaInput';
import DatepickerInput from '../../components/forms/DatepickerInput';
import CheckboxInput from '../../components/forms/CheckboxInput';
import { FieldArray } from 'react-final-form-arrays';
import arrayMutators from 'final-form-arrays';
import { WhenFieldChangesSetAnotherField } from '../../libraries/forms';
import { tr } from 'date-fns/locale';
import clsx from 'clsx';

class BenefitConsumption extends React.Component {
  constructor(props) {
    super(props);
    this.t = this.props.t;
    this.state = {
      business: {},
      businesses: [],
      imageUpdated: false,
      creatingNew: false,
      editing: false,
      files: {},
      loading: true,
      remove: false,
      setting: null,
      submitting: false,
      titles: '',
      user: {},
      view: false
    };
    this.submit = null;
    this.reset = null;
    this.breadcrumbs = [this.t('Benefits Consumption'), this.t('View')];
    this.titles = this.t('Benefit details');
  }
  // ** Life cycle
  componentDidMount() {
    const params = this.props.match.params;
    if (params.id) {
      this.setState({
        remove: true,
        view: true,
      });
      this.getBenefitConsumption(params.id);
    } else {
      this.setState({ titles: this.t('Benefit Consumption'), creatingNew: false, loading: false });
    }
  }

  getBenefitConsumption = async id => {
    await this.props.onGetTransaction(id);
    const { item, error } = this.props.transaction;
    if (error) {
      notify(this.t(error.message));
    } else {
      this.setState({
        transaction: item,
      }, () => {
        const idBenefit = this.props.transaction.item?.json_data.benefit.id
        this.getBenefit(idBenefit);
      });
    }
    this.getCAtegoryAndCompany(item);
  };

  componentWillUnmount() {
    this.props.onClearCurrent();
  }

  goBack = () => {
    history.push(config.ROUTES.BENEFITS_CONSUMPTIONS);
  };

  onFileChangeImageInput = async ({ file, data, name }) => {
    const { creatingNew, transaction } = this.state;
    this.setState({ submitting: true });
    let imageIdentifier;
    if (creatingNew) {
      imageIdentifier = `${parseInt(Math.random() * 10 ** 9)}`;
      data.append('identifier', imageIdentifier);
    } else {
      data.append('identifier', `${transaction.id}_${name}`);
    }
    await this.props.onPictureUpload(data);
    const error = this.props.files.error;
    if (error) {
      notify(this.t(error.message));
    } else {
      this.setState({
        submitting: false, files: {
          ...this.state.files,
          [name]: this.props.files.files.fileInfo.location
        },
      });

      return this.props.files.files.fileInfo.location;
    }
  };

  onClearImageField = ({ name }) => {
    if (name) this.setState({ files: { ...this.state.files, [name]: null } });
  };


  getCAtegoryAndCompany = async (trx) => {
    await this.props.onGetActionType(trx.json_data.benefit.pack);
    await this.props.onGetCompany(trx.owner);
    const { category, company } = this.props;

    if (category.error || company.error) {
      return notify(this.t(category.error.message || company.error.message));
    }

    const trxWithCategory = {
      ...trx,
      category: category.item.name,
      json_data: {
        ...trx.json_data,
        benefit: {
          ...trx.json_data.benefit,
          pack: category.item.name,
          owner: company.item.name
        },
      }
    }


    this.setState({ transaction: trxWithCategory, loading: false });
  };

  getBenefit = async (id) => {
    await this.props.onGetBenefit(id);
    const { benefit } = this.props;
    if (benefit.error) {
      notify(this.t(benefit.error.message));
    } else {
      this.setState({ benefitLink: benefit.item?.json_data.url })
    }

  }


  render() {
    const { benefitCategories, creatingNew, view, editing, submitting, transaction, loading, files, businesses, setting, benefitLink } = this.state;
    const { roles } = this.props.auth.user;
    // generar objeto json sin anidado
    const flatJSON = { ...transaction?.json_data };

    // generar objeto rules sin anidado tras parsear el json
    const flatRules = { ...transaction?.json_data?.rules };

    ////////////////////////////////////////////////////////////
    //fixme: SE BORRA EL FORMULARIO DESPUES DE SUBIR LA IMAGEN//
    ////////////////////////////////////////////////////////////

    // crear el objeto privado (dif del estado)
    const _transaction = {};
    _transaction.valuesPorNivel = [];
    for (let i = 0; i < setting?.value; i++) {
      //console.log("___setting value", i, { nivel: i, type_of_discount: config.BENEFITS.DISCOUNT_TYPES[0].value })
      _transaction.valuesPorNivel.push({ nivel: i, type_of_discount: config.BENEFITS.DISCOUNT_TYPES[0].value });
    }

    // mapear transaction al objeto _transaction
    if (!isEmptyObject(transaction) && transaction !== undefined && transaction !== null) {
      for (let key of Object.keys(transaction)) {
        // se agregan las propiedades que no estaban nesteadas primero
        if (key !== 'json_data' && key !== 'rules') {
          _transaction[key] = transaction[key];
        }
      }

      // luego se cargan las propiedades desanidadas
      for (let jsonObject of Object.keys(flatJSON)) {
        _transaction[jsonObject] = flatJSON[jsonObject];
      }
      _transaction.benefit_name = _transaction.benefit.name;
      _transaction.benefit_country = _transaction.benefit.code;
      _transaction.benefit_category = _transaction.benefit.pack;
      _transaction.benefit_business = _transaction.benefit.owner;

      _transaction.user_name = _transaction.user.first_name + " " + _transaction.user.last_name;
      _transaction.user_email = _transaction.user.email
      _transaction.url = benefitLink

    }

    // Layout actions
    const actions = {
      main: {
      },
      secondaries: [
        {
          onClick: e => history.push(config.ROUTES.BENEFITS_CONSUMPTIONS),
          title: this.t('Go Back'),
          icon: 'cheveron_left',
          disabled: submitting,
          visible: true,
          className: 'btn-accent'
        },
      ]
    };

    // ** Form validation functions
    const required = value =>
      validateIsfilled(value) || (view && !editing) ? undefined : this.t('This field is required');

    const validateForm = values => {
      const errors = {};
      errors.name = required(values.name);
      errors.business = required(values.business);
      errors.category = required(values.category);
      errors.detail = required(values.detail);
      return errors;
    };

    const isUrl = value => (validateURL(value) || (view && !editing) || !value) ? undefined : this.t('Ingresar URL válido');

    // ** Actual render
    return (
      <LayoutWithSidebar
        main={{ className: 'text-content-400' }}
        header={{
          breadcrumbs: this.breadcrumbs
        }}
        container={{ className: 'px-8' }}
        actions={actions}>
        <ToastContainer />
        <PanelWindow outerTitle={this.titles} loading={loading}>
          <Form
            initialValues={(view || editing) ? (_transaction || {}) : _transaction}
            onSubmit={() => { }}
            mutators={{
              ...arrayMutators
            }}
            validate={validateForm}>
            {({ handleSubmit, form, submitting, pristine, values, form: { mutators: { push, pop } } }) => {
              this.submit = handleSubmit;
              this.reset = form.reset;
              return (
                <FormLayout form={form} onSubmit={this.onSubmit} values={values}>
                  <div className="flex">
                    <Field
                      name="benefit_name"
                      component={TextInput}
                      placeholder={capitalize(this.t('name of benefit'))}
                      label={capitalize(this.t('name of benefit'))}
                      readOnly={view && !editing}
                      className="px-3 mb-3 w-full"
                    />
                    <Field // EMPRESA
                      name="benefit_business"
                      component={TextInput}
                      label={capitalize(this.t('benefit store'))}
                      placeholder={capitalize(this.t('store'))}
                      readOnly={view && !editing}
                      className="px-3 mb-3 w-full"
                    />
                  </div>
                  <div className="flex">
                    <Field // RUBRO
                      name="benefit_category"
                      component={TextInput}
                      label={capitalize(this.t('benefit category'))}
                      placeholder={capitalize(this.t('category'))}
                      readOnly={view && !editing}
                      className="px-3 mb-3 w-full"
                    />
                    <Field // PAIS
                      name="benefit_country"
                      component={TextInput}
                      label={capitalize(this.t('benefit country'))}
                      placeholder={capitalize(this.t('country'))}
                      readOnly={view && !editing}
                      className="px-3 mb-3 w-full"
                    />
                  </div>
                  <div className="flex">
                    <Field
                      name="user_name"
                      component={TextInput}
                      placeholder={capitalize(this.t('name of user'))}
                      label={capitalize(this.t('name of user'))}
                      readOnly={view && !editing}
                      className="px-3 mb-3 w-full"
                    />
                    <Field // EMPRESA
                      name="user_email"
                      component={TextInput}
                      label={capitalize(this.t('user email'))}
                      placeholder={capitalize(this.t('email'))}
                      // options={businessesOptions}
                      // empty={capitalize(this.t('select a store'))}
                      readOnly={view && !editing}
                      className="px-3 mb-3 w-full"
                    />
                  </div>
                  <div className='flex'>
                    <Field
                      name="credits"
                      component={IntegerInput}
                      placeholder={capitalize(this.t('creditos'))}
                      label={capitalize(this.t('costo'))}
                      parse={v => formatOnlyNumberInput(v)}
                      readOnly={view && !editing}
                      className="px-3 mb-3 w-full"
                    />
                    {/* <Field // RUBRO
                      name="status"
                      component={TextInput}
                      label={capitalize(this.t('transaction status'))}
                      placeholder={capitalize(this.t('status'))}
                      // options={categoryTypes}
                      // empty={capitalize(this.t('select a category'))}
                      readOnly={view && !editing}
                      className="px-3 mb-3 w-full"
                    /> */}
                  </div>

                  <Field
                    name="url"
                    component={TextInput}
                    placeholder={capitalize(this.t('brand website'))}
                    label={capitalize(this.t('brand website'))}
                    readOnly={view && !editing}
                    className="xl:col-span-4 px-3 mb-3 md:mb-0"
                    parse={parseUrl} validate={isUrl}
                  />
                </FormLayout>
              );
            }}
          </Form>
        </PanelWindow>
      </LayoutWithSidebar>
    );
  }
}

const mapStateToProps = state => {
  return {
    auth: state.users.auth,
    benefit: state.benefits.current,
    benefits: state.benefits.list,
    transaction: state.transactions.current,
    files: state.files,
    user: state.users.current,
    category: state.categories.current,
    company: state.companies.current,

  };
};

const mapDispatchToProps = dispatch => {
  return {
    onSaveOrUpdate: params => dispatch(transactionsActions.saveOrUpdate(params)),
    onGetTransaction: params => dispatch(transactionsActions.get(params)),
    onGetBenefits: (params) => dispatch(benefitsActions.getAll(params)),
    onGetBenefit: (params) => dispatch(benefitsActions.get(params)),
    onGetUser: params => dispatch(usersActions.get(params)),
    onClearCurrent: () => dispatch(transactionsActions.clearCurrent()),
    onPictureUpload: params => dispatch(filesActions.upload(params)),
    onGetActionType: id => dispatch(categoriesActions.get(id)),
    onGetCompany: (id) => dispatch(companiesActions.get(id)),


  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(BenefitConsumption));