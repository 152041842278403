import React from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { history } from '../../../routes';
import { ToastContainer, notify } from '../../../libraries/notifications';
import config from '../../../config';
import ButtonIcon from '../../../components/commons/ButtonIcon';
import LayoutWithSidebar from '../../../components/layout/LayoutWithSidebar';
import PanelLayout from '../../../components/PanelLayout';
import StyledDataTable from '../../../components/commons/StyledDataTable';
import Swal from 'sweetalert2';
import prizesActions from '../../../context/benefits/actions';
import businessesActions from '../../../context/businesses/actions';
import companiesActions from '../../../context/companies/actions';
import PrivateButton from '../../../components/commons/PrivateButton';
import { capitalize, getOwner } from '../../../libraries/utils';
import actions from '../../../context/transactions/actions';
import { format } from "date-fns";


const customSwal = Swal.mixin({
  customClass: {
    confirmButton: 'btn btn-primary mx-1',
    cancelButton: 'btn btn-outline btn-primary mx-1',
    title: 'swal2-title',
    htmlContainer: 'swal-text'
  },
  buttonsStyling: false,
  background: '#fff'
});

class Prizes extends React.Component {
  constructor(props) {
    super(props);
    this.t = this.props.t;
    this.state = {
      prizes: [],
      loading: false,
      windowWidth: window.innerWidth,
      sort: null,
      search: null
    };
    this.breadcrumbs = [capitalize(this.t('prizes'))];
  }

  componentDidMount() {
    this.setState({ loading: true });
    this.getPrizes();
    window.addEventListener('resize', this.handleResize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  handleResize = () => {
    this.setState({ windowWidth: window.innerWidth });
  };

  getCompanies = async () => {
    //await this.props.onGetCompanies({ owner: this.props.auth.user.id });
    await this.props.onGetCompanies({ owner: getOwner(this.props.auth.user) });

    if (this.props.companies.error) {
      notify(this.t(this.props.companies.error.messsage));
    } else {
      return this.props.companies.items
    }
  };

  getPrizes = async (page_num = 1) => {
    const { search, sort } = this.state;
    const owner = await this.getCompanies();
    const params = { owner: owner[0].id, type: config.TYPES.BENEFITS.PRIZE };

    if (search && search !== '') {
      params.where = { json_data: [] };
      if (search && search !== '') {
        params.where = { custom: { name: `%${search.toLowerCase()}` } };
      }
    }

    if (sort) {
      params.order_by = sort.order_by;
      params.order_direction = sort.order_direction;
    }

    await this.props.onGetAllPrizes(params);
    const { prizes } = this.props;
    if (prizes.error) {
      notify(this.t(prizes.error.message));
    } else {
      
      const transactionPrizez = await Promise.all(prizes.items.map(async (p) => (await this.getPrizeUses(p.id))))
      const prizesWithCountTransaction = prizes.items?.map((p,i) =>({...p,transactionCount:transactionPrizez[i]?.filter(t => t.status === 'consumed').length||0}) )
      
      this.setState({ prizes: prizesWithCountTransaction, loading: false });
    }

  };

  customSort = async (field, order, currentPage) => {
    this.setState({ sort: { order_by: field, order_direction: order } }, () => this.getPrizes(currentPage));
  };

  getPrizeUses = async id => {
    this.setState({ loading: true });
    let params = {
      target: id,
      type: config.TRANSACTIONS.BENEFIT.TYPE,
      owner: this.props.auth.user.unique_id,
    };
    await this.props.onGetTransactions(params);
    const { items, error } = this.props.transactions;
    if (error) return null
    return items
  }


  onRemove = (id, resetRows) => {
    const { prizes } = this.props;
    customSwal
      .fire({
        title: this.t(resetRows ? capitalize('delete multiple elements') : '¿Are you sure?'),
        text: this.t("You can't roll back this operation"),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.t('Yes'),
        cancelButtonText: this.t('Cancel')
      })
      .then(async result => {
        if (result.isConfirmed) {
          prizes.loading = true;
          if (resetRows) {
            for (let index = 0; index < id.length; index++) {
              const element = id[index];
              await this.props.onRemove(element.id);
            }
            resetRows();
            if (prizes.error) {
              notify(this.t(prizes.error.message));
            } else {
              this.getPrizes(this.state.currentPage);
            }
          } else {
            await this.props.onRemove(id);
            if (prizes.error) {
              notify(this.t(prizes.error.message));
            } else {
              this.getPrizes(this.state.currentPage);
            }
          }
        }
      });
  };

  onEdit = id => {
    history.push(`${config.ROUTES.PRIZES}/${id}`);
  };

  onNew = () => {
    history.push(config.ROUTES.PRIZES_NEW);
  };

  onSearch = data => {
    if (this.state.search !== data.search) {
      this.setState({ search: data.search || '' }, () => this.getPrizes());
    }
  };

  hideColumns = number => {
    if (this.state.windowWidth < number) {
      return 'lg';
    }
    return null;
  };

  
  render() {
    const { windowWidth, prizes, loading } = this.state;
    const { query, auth } = this.props;
    const { roles } = auth.user;


    const obtenerValorJSonData = (key, object) => {
      const jsonData = object.json_data
      if (!jsonData) return null;
      const data = typeof jsonData == 'string' ? JSON.parse(jsonData) : jsonData;
      return data[key]
    }

    const columns = [
      {
        name: capitalize(this.t('name of prize')),
        sortable: true,
        selector: row => row['name'],
        sortSelector: 'name',
        maxWidth: `${windowWidth - 64 - 465}px`
      },
      {
        name: capitalize(this.t('Grupo')),
        center: true,
        sortable: true,
        selector: row => row['pack'],
        sortSelector: 'pack',
        maxWidth: `${windowWidth - 64 - 465}px`
      },
      {
        name: capitalize(this.t('Cant total')),
        center: true,
        sortable: true,
        selector: row => obtenerValorJSonData('rules', row).group_uses == 0 ? 'Ilimitado' : obtenerValorJSonData('rules', row).group_uses,
        sortSelector: 'pack',
        maxWidth: `${windowWidth - 64 - 465}px`
      },
      {
        name: capitalize(this.t('Entregados')),
        center: true,
        sortable: true,
        selector: row =>row.transactionCount,
        sortSelector: 'pack',
        maxWidth: `${windowWidth - 64 - 465}px`
      },
      {
        name: capitalize(this.t('Finalización')),
        center: true,
        sortable: true,
        selector: row => format(new Date(row.json_data?.rules.end_date), 'dd/MM/yyyy'),
        sortSelector: 'pack',
        maxWidth: `${windowWidth - 64 - 465}px`
      },
      {
        name: capitalize(this.t('Cant disponibles')),
        center: true,
        sortable: true,
        selector: (row) => {
          if (obtenerValorJSonData('rules', row).group_uses == 0) return 'Ilimitado'
          const disp = obtenerValorJSonData('rules', row).group_uses - row.transactionCount;
          return disp == 0 ? <p className='bg-red-400   px-5 py-2 rounded-3xl'>{disp}</p> : disp
        },
        sortSelector: 'pack',
        maxWidth: `${windowWidth - 64 - 465}px`
      },
      {
        name: capitalize(this.t('UI actions')),
        allowOverflow: true,
        center: true,
        width: '130px',
        hide: this.hideColumns(370),
        cell: row => {
          return (
            <div className="flex justify-center align-center rounded-full gap-2">
              <ButtonIcon onClick={() => this.onEdit(row.id)} icon="view_show" buttonClassName="btn-link text-gray-600 bg-gray-100 btn-sm" className="w-5" />
              <PrivateButton control="delete">
                <ButtonIcon onClick={() => this.onRemove(row.id)} icon="trash" buttonClassName="btn-link text-error bg-gray-100 btn-sm" />
              </PrivateButton>
            </div>
          );
        }
      }
    ];

    const actions = {
      main: {
        onClick: this.onNew,
        title: this.t('New'),
        checkPermissions: 'insert'
      },
      secondaries: [],
      search: { onSearch: this.onSearch, title: this.t('Search') }
    };

    return (
      <LayoutWithSidebar
        main={{ className: 'text-content-400' }}
        header={{
          breadcrumbs: this.breadcrumbs
        }}
        container={{ className: 'px-8' }}
        actions={actions}
        loading={loading}>
        <ToastContainer />
        <PanelLayout>
          <StyledDataTable
            data={prizes}
            columns={columns}
            selectableRows={roles.includes(config.ROLES.COMPANIES) ? windowWidth > 600 : null}
            query={query}
            getDataFunction={this.getPrizes}
            multipleDeleteFunction={roles.includes(config.ROLES.COMPANIES) ? this.onRemove : null}
            pagination
            loading={loading}
            customSort={this.customSort}
            screenWidth={windowWidth}
            onRowExpand={() => { }}
          />
        </PanelLayout>
      </LayoutWithSidebar>
    );
  }
}

const mapStateToProps = state => {
  return {
    auth: state.users.auth,
    companies: state.companies.list,
    prizes: state.benefits.list,
    businesses: state.businesses.list,
    query: state.actions.list.query,
    transactions: state.transactions.list
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onGetAllPrizes: params => dispatch(prizesActions.getAll(params)),
    onGetBusinesses: params => dispatch(businessesActions.getAll(params)),
    onGetCompanies: params => dispatch(companiesActions.getAll(params)),
    onRemove: id => dispatch(prizesActions.del(id)),
    onGetTransactions: params => dispatch(actions.getAll(params))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Prizes));
